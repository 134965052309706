.App {
  text-align: left;
  background-color: #c0c0c0  /* #4c4849; modal dialog outer background color */
}
.AppSub {
  text-align: left;
  background-color: #fff;  /* #4c4849; modal dialog outer background color */
  border-radius: 20px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Company-logo{
    display: flex;
    text-align: center;
    justify-content: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:  #C0C0C0;  /* background color */
  min-height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color:  rgb(76, 79, 77); /* text color */

}

.App-link {
  color: #61dafb;
  font-size: 15px;
 font-style: italic;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rounded-corners{
  border-radius: 10px;
  height: 25;
  width: 25;
}

.resultSet-rounded-corners{
  border: 1px solid lightgray;
  padding: 1%;
  border-radius: 20px;
  height: 25;
  width: 25;
}
.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: "Background.jpeg";
  background-size: "cover";
  height: 80vh;
  margin-top: auto; 
  font-size:50px;
  background-repeat:no-repeat; 
}