.card-img-top {
    width: 90%;
    height: 20vh;
    border-style: none;
    display:block;
    box-sizing: 90%; 
    object-fit: contain;
    border: 1px solid white;
    }

    .slide-img-props{
        width: 100%;
        height: 100%;
        box-sizing: 90%;
        border-style: none;
        object-fit: cover;
    }


    